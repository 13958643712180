import React from "react";
import { useEffect, useState } from "react";
import {BsPaintBucket} from "react-icons/bs"

export const ScrollTop = () => {
  
    const[ backToTopButtom, setBackToTopButton] = useState(false);

    useEffect(() =>{
        window.addEventListener("scroll", () =>{
            if(window.scrollY > 100){
                setBackToTopButton(true)
            } else{
                setBackToTopButton(false)
            }
        })
    }, [])
    
    const scrollUp = () =>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
  
    return (
        <div className="btn-top2">
        {backToTopButtom &&(
            <button style={{
                position: "fixed", 
                bottom:"80px",
                background:"var(--main-color)",
                right:"23px",
                height:"45px",
                width:"45px",
                color:"white",
                fontSize:"40px", 
                zIndex: "99",
                borderRadius:"100%",
                border:"solid var(--main-color) 2px",
                fontSize:"30px",
                color:"white"
            }}
            onClick={scrollUp}
            ><BsPaintBucket/></button>
        )}
    </div>
  )
}
