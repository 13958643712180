import React,{useContext} from 'react';
import { GlobalDataContext } from '../context/context';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactPage from './section-components/contact-page';
import Footer from './global-components/footer';
import Data from '../api/data.json'

const Contact = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <PageHeader headertitle="Contact" Subheader="Contact" backgroundImage={rpdata?.gallery?.[7]}/>
        <ContactPage />
        <Footer />
    </div>
}

export default Contact

